<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Connexion</h4>
                  <p>Bienvenue sur votre administration, veuillez-vous identifier.</p>
                </div>
                <br/>

                <!-- PAGE LOGIN == 0 -->
                <div>
                  <vs-input
                      name="login"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label-placeholder="Login utilisateur"
                      v-model="login"
                      class="w-full"
                      v-on:keyup.enter="Flogin()" />

                  <vs-input
                      type="password"
                      name="password"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Mot de passe"
                      v-model="password"
                      class="w-full mt-6" 
                      v-on:keyup.enter="Flogin()" />

                  <div class="flex flex-wrap justify-between my-5">
                      <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Mémoriser</vs-checkbox>
                      <router-link to="/reset">Mot de passe oublié ?</router-link>
                  </div>
                  <!--<vs-button  type="border">Register</vs-button>-->
                  <vs-button class="float-right" v-bind:disabled="btn_login_disabled" v-on:click="Flogin()">Login</vs-button>
                  <br/>
                </div>

              </div>
            </div>
          </div>
        </div>
        {{ autoLogin() }}
      </vx-card>
      <br/>
      <vx-card 
        v-if="btn_login_disabled==true"
        content-color="#fff"
        card-background="linear-gradient(120deg, #7f7fd5, #86a8e7, #91eae4)"
      >
          {{ info_bdd }}
        </vx-card>
    </div>
    </div>
</template>

<style lang="scss">
</style>


<script>
export default {
    data(){
        return{
          login: "",
          password: "",
          checkbox_remember_me: false,
          btn_login_disabled: false,


          info_bdd : '',
          timer : '',
        }
    },
    created(){
      this.timer = setInterval(this.getInfoLoad, 100);
    },
    beforeDestroy () {
      clearInterval(this.timer);
    },
    methods:{
      //-------------------------------------------------------------
      /*Notification success */
      //-------------------------------------------------------------
      Flogin:function()
      {
        //Verif
        if(this.login.length < 3 )
          return;
        if(this.password.length < 5 )
          return;
        
        let root = this;

        //Message de connexion
        root.$notify.stand('Connexion en cours','Veuillez patienter ...');

        //fermer le login
        root.btn_login_disabled = true;

        //Login
        this.$srvApi.login( this.login, this.password, this.checkbox_remember_me )
        .then(function()
        {
          root.$notify.stand('Installation','Veuillez patienter ...');
          //console.log("BDD LOAD: "+(new Date().toUTCString()) );
          root.$bddRefresh.actualise(function()
          {
              //console.log("BDD END: "+(new Date().toUTCString()) );
              root.$notify.success('Bienvenue','');
              root.$router.push('/admin');
          });
        })
        .catch(function()
        {
          root.$notify.error('Login erreur','Erreur du Login ou mot de passe');
          root.btn_login_disabled = false;
        });
      },




      //-------------------------------------------------------------
      /*Auto login */
      //-------------------------------------------------------------
      autoLogin:function()
      {
        let root = this;

        if( root.btn_login_disabled == false )
        if( root.$srvApi.isLocalLogin() )
        {
          root.$notify.success('Connexion établie','Bienvenue dans votre administration');
          root.$router.push('/admin');
        }
      },



      //-------------------------------------------------------------
      /*Info etat insetion BDD*/
      //-------------------------------------------------------------
      getInfoLoad:function()
      {
        this.info_bdd = this.$bddRefresh.getInfo();
      }
    }
};
</script>